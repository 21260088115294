
import * as Icon from 'react-bootstrap-icons';
import './App.css';

function App() {

  return (
    <div className="App">
      <nav id="menu-bar" className="navbar fixed-top navbar-expand-lg navbar-light" style={{backgroundColor: "#ffffff", boxShadow: "0px 0px 5px 1px #a9a9a9"}}>
        <div className="container-fluid">
          <a className="navbar-brand" href="#scrollspyInicio">
            <img src="logov2.png" alt="" height="40" className="d-inline-block align-text-top"/>
          </a>
          <button className="btn btn-outline-success me-2" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom"><Icon.Calendar2Check />  Reserva tu hora</button>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="#scrollspyInicio">Inicio</a>
              </li>
{/*               <li className="nav-item">
                <a className="nav-link" href="#scrollspyServicios">Servicios</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#scrollspyNosotros">Nosotros</a>
              </li> */}
              <li className="nav-item">
                <a className="nav-link" href="#scrollspyContacto">Contacto</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div data-bs-spy="scroll" data-bs-target="#menu-bar" data-bs-offset="56" className="scrollspydiv" tabIndex="0">

        <div id="scrollspyInicio" className="ssCategory row">
          <div className="col d-flex justify-content-center text-center align-items-center"> 
            <img src='logolandslg.png' className="App-logo" alt="logo" />
          </div>
        </div>
{/*     <div id="scrollspyServicios" className="ssCategory row"></div>
        <div id="scrollspyNosotros" className="ssCategory row"></div> */}
        <div id="scrollspyContacto" className="ssCategory row">

          <div class="row row-cols-1 row-cols-md-2 g-4 sucursalesGrid">
            <div class="col">
              <div class="card">
              <iframe title="Huerfanos" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3329.358404095194!2d-70.65563968493817!3d-33.43996780436026!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c5a4397cdf13%3A0x6bc8497a780ab393!2sHu%C3%A9rfanos%201178%2C%20Santiago%2C%20Regi%C3%B3n%20Metropolitana!5e0!3m2!1ses-419!2scl!4v1621819648433!5m2!1ses-419!2scl" style={{border:0}} allowfullscreen="" loading="lazy"></iframe>
                <div class="card-body">
                  <h5 class="card-title">SANTIAGO CENTRO</h5>
                  <p class="card-text">Huérfanos 1178, Oficina 503</p>
                  <p class="card-text">+56 2 2760 3500</p>
                </div>
                <div class="card-footer">
                  <small class="text-muted">Sucursal Huérfanos</small>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card">
              <iframe title="Apoquindo" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3330.323422996484!2d-70.59045098493903!3d-33.41481140306978!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cf17cffe5b09%3A0x147b64ad7af282cf!2sAv.%20Apoquindo%204100%2C%20Las%20Condes%2C%20Regi%C3%B3n%20Metropolitana!5e0!3m2!1ses-419!2scl!4v1621819819315!5m2!1ses-419!2scl" style={{border:0}} allowfullscreen="" loading="lazy"></iframe>
                <div class="card-body">
                  <h5 class="card-title">LAS CONDES</h5>
                  <p class="card-text">Apoquindo 4100, Oficina 402</p>
                  <p class="card-text">+56 2 2760 3500</p>
                </div>
                <div class="card-footer">
                  <small class="text-muted">Sucursal Apoquindo</small>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>

      <div className="offcanvas offcanvas-bottom" tabIndex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasBottomLabel">RESERVA TU HORA</h5>
          <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body small">
        <a className="btn btn-outline-success mb-2" role="button" href="tel:+56227603500"><Icon.TelephoneFill /> +56 2 2760 3500</a>
        <a className="btn btn-outline-success mb-2" role="button" href="https://wa.link/awmvhx"><Icon.Whatsapp /> Conversemos por Whatsapp</a>
        </div>
      </div>

    </div>
  );
}

export default App;
